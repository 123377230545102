import Header from "../src/components/header/header";
import ChatBot from "../src/screens/bot/bot";
import { useEffect } from "react";
import useInitialConfigStore from "./storeModules/useInitialConfigStore";
import GoogleTagManager from "./components/gtm/gtm";
import useConsorcioStore from "./storeModules/useConsorcioStore";
import { TipoBotEnum, TipoSimulacaoEnum } from "./utils/constants";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function App({ botType, title, description, indexar }) {
  const { setInitialConfigStore, bannerSrc } = useInitialConfigStore();
  const { setConsorcioStore } = useConsorcioStore();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const referralcodeFromUrl = urlParams.get("referralcode");
  const idc = urlParams.get("idc");

  const canonicalUrl = window.location.href;

  useEffect(() => {
    if (botType === TipoBotEnum.MYCON) {
      setInitialConfigStore(2, "campanhaId");
      setInitialConfigStore(161, "compraId");
      setInitialConfigStore(90, "empresaId");
      setInitialConfigStore("completa", "tipoTabela");
      setInitialConfigStore("Site Mycon", "brand");
      setInitialConfigStore(
        "https://chatbot-cdn.mycon.com.br/mycon/chat-conecta-junho-2024.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_INTEGRAL, "tipoPlano");
      setInitialConfigStore("MYCON", "botType");
    }

    if (botType === TipoBotEnum.LIVE) {
      setInitialConfigStore(851, "campanhaId");
      setInitialConfigStore(123, "compraId");
      setInitialConfigStore(50, "empresaId");
      setInitialConfigStore(true, "desconto");
      setInitialConfigStore("paga_metade", "tipoTabela");
      setInitialConfigStore("Site Mycon", "brand");
      setInitialConfigStore(
        "https://chatbot-cdn.mycon.com.br/mycon/banner-mycon-acao-oferta-taxa-promo.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_PAGAMETADE, "tipoPlano");
      setInitialConfigStore("LIVE", "botType");
    }

    if (botType === TipoBotEnum.PERFORMANCE) {
      setInitialConfigStore(789, "campanhaId");
      setInitialConfigStore(161, "compraId");
      setInitialConfigStore(90, "empresaId");
      setInitialConfigStore(false, "desconto");
      setInitialConfigStore("paga_metade", "tipoTabela");
      setInitialConfigStore("Site Mycon Performance", "brand");
      setInitialConfigStore(
        "https://chatbot-cdn.mycon.com.br/mycon/chat-modo-consquista-paga-metade-junho-2024.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_INTEGRAL, "tipoPlano");
      setInitialConfigStore("PERFORMANCE", "botType");
    }

    if (botType === TipoBotEnum.MODO_TURBO) {
      setInitialConfigStore(789, "campanhaId");
      setInitialConfigStore(164, "compraId");
      setInitialConfigStore(93, "empresaId");
      setInitialConfigStore(true, "desconto");
      setInitialConfigStore("paga_metade", "tipoTabela");
      setInitialConfigStore("Site Mycon Modo Turbo", "brand");
      setInitialConfigStore(
        "https://chatbot-cdn.mycon.com.br/mycon/chat-modo-turbo-referencial-desconto-2024.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_PAGAMETADE, "tipoPlano");
      setInitialConfigStore("MODO_TURBO", "botType");
    }

    if (botType === TipoBotEnum.ANIVERSARIO) {
      setInitialConfigStore(851, "campanhaId");
      setInitialConfigStore(164, "compraId");
      setInitialConfigStore(93, "empresaId");
      setInitialConfigStore(true, "desconto");
      setInitialConfigStore("paga_metade", "tipoTabela");
      setInitialConfigStore("Site Mycon", "brand");
      setInitialConfigStore(
        "https://chabot-cdn.mycon.com.br/mycon/banner-aniversario-mycon-acao-mes.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_PAGAMETADE, "tipoPlano");
      setInitialConfigStore("ANIVERSARIO", "botType");
    }

    const referralcode =
      referralcodeFromUrl && referralcodeFromUrl !== "null"
        ? referralcodeFromUrl
        : localStorage.getItem("referralcode");

    if (referralcode) {
      setInitialConfigStore(605, "campanhaId");
      setInitialConfigStore(130, "compraId");
      setInitialConfigStore(58, "empresaId");
      setInitialConfigStore(true, "desconto");
      setInitialConfigStore("tabelaintegral", "tipoTabela");
      setInitialConfigStore("Mycoins", "brand");
      setInitialConfigStore(
        "https://chatbot-cdn.mycon.com.br/mycon/chat-conecta-junho-2024.png",
        "bannerSrc"
      );
      setConsorcioStore(TipoSimulacaoEnum.CREDITO_INTEGRAL, "tipoPlano");
      setInitialConfigStore("MYCOINS", "botType");
      setInitialConfigStore(referralcode, "referralcode");

      if (referralcodeFromUrl && referralcodeFromUrl !== "null") {
        localStorage.setItem("referralcode", referralcodeFromUrl);
      }
    }

    if (idc) {
      setInitialConfigStore(idc, "campanhaId");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content={indexar} />
      </Helmet>
      <GoogleTagManager gtmId="GTM-PVQ72FR" />
      <Header />
      <ChatBot bannerSrc={bannerSrc} />
    </div>
  );
}

export default App;
