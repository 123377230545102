import React, { useState } from "react";
import "./card-payment.scss";
import visaLogo from "../../assets/flags/visa.png";
import mastercardLogo from "../../assets/flags/mastercard.png";
import amexLogo from "../../assets/flags/amex.png";
import defaultLogo from "../../assets/flags/default.png";
import chip from "../../assets/flags/chip.png";
import { useForm, Controller } from "react-hook-form";
import useUserStore from "../../storeModules/useUserStore";
import { nomeRazaoRegex, removeNonDigits } from "../../utils/regex";
import { PatternFormat } from "react-number-format";
import valid from "card-validator";
import { cpf, cnpj } from "cpf-cnpj-validator";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import useBotControlStore from "../../storeModules/useBotControlStore";
import { sendPaymentCartao } from "../../api/requests";
import LoadingDots from "../loadingDots";
import approvedPaymentImage from "../../assets/images/cartao-aprovado.gif";

const getCardFlag = (cardNumber) => {
  const number = cardNumber.replace(/\s+/g, "");

  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(number)) {
    return "visa";
  } else if (/^5[1-5][0-9]{14}$/.test(number)) {
    return "mastercard";
  } else if (/^3[47][0-9]{13}$/.test(number)) {
    return "amex";
  } else {
    return "default";
  }
};

const PaymentModal = ({ isVisible, onClose, onPaymentSuccess }) => {
  const [cardInfo, setCardInfo] = useState({
    number: "",
    name: "",
    expiry: "",
    flag: "default",
    cvv: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false); // State for flipping the card
  const [, setUpdateCard] = useState(0);

  const fetchedPropostaId = useConsorcioStore(
    (state) => state.fetchedProposta.propostaID
  );
  const { tipoPessoa } = useUserStore((state) => state.contrato);
  const isPessoaFisica = tipoPessoa === 0;

  const setMessages = useBotControlStore((state) => state.setMessages);
  const setCurrentStep = useBotControlStore((state) => state.setCurrentStep);
  const setPayment = useUserStore((state) => state.setPayment);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    formState,
    trigger,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cartaoBandeira: "",
      cartaoCodigoSeguranca: "",
      cartaoCpfTitular: "",
      cartaoNomeTitular: "",
      cartaoNumero: "",
      cartaoValidadeMes: 0,
      cartaoValidadeAno: 0,
      cartaoParcelas: 0,
      cartaoId: 0,
      propostaId: 0,
      cartaoValidadeMesAno: "",
    },
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setUpdateCard((prev) => prev + 1);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (!isVisible) return null;

  const handleFocusCvv = () => {
    setIsFlipped(true); // Flip to show the back side
  };

  const handleBlurCvv = () => {
    setIsFlipped(false); // Flip back to show the front side
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const {
      cartaoCodigoSeguranca,
      cartaoCpfTitular,
      cartaoNomeTitular,
      cartaoNumero,
      cartaoParcelas,
      cartaoId,
      cartaoValidadeMesAno,
    } = data;

    const [mes, ano] = cartaoValidadeMesAno.split("/");

    const paymentProps = {
      cartaoBandeira: "VISA",
      cartaoCodigoSeguranca,
      cartaoCpfTitular,
      cartaoNomeTitular,
      cartaoNumero,
      cartaoValidadeMes: mes,
      cartaoValidadeAno: ano,
      cartaoParcelas,
      cartaoId,
      propostaId: fetchedPropostaId,
    };

    sendPaymentCartao(paymentProps)
      .then((response) => {
        const { data } = response.data;

        if (response.status === 200) {
          setMessages({
            id: "payment-success",
            sender: "bot",
            text: "",
            component: (
              <img
                className="payment-image"
                src={approvedPaymentImage}
                alt="Pagamento aprovado"
              />
            ),
          });

          window.dataLayer.push({
            event: "Pagamento Processado",
            metodo_pagamento: "cartao_de_credito",
            status_transacao: "sucesso",
          });

          setPayment(data.cartaoAutorizacao, "cartaoAutorizacao");
          setCurrentStep(90);
        } else {
          window.dataLayer.push({
            event: "Pagamento Processado",
            metodo_pagamento: "cartao_de_credito",
            status_transacao: "pendente",
          });

          setCurrentStep(89);
        }
      })
      .catch(() => {
        window.dataLayer.push({
          event: "Pagamento Processado",
          metodo_pagamento: "cartao_de_credito",
          status_transacao: "recusado",
        });

        setCurrentStep(88);
      })
      .finally(() => {
        onClose();
        setIsLoading(false);
      });
  };

  const cardLogoMap = {
    visa: visaLogo,
    mastercard: mastercardLogo,
    amex: amexLogo,
    default: defaultLogo,
  };

  return (
    <div className="payment-modal">
      <div className="modal-content">
        <div className={`credit-card ${isFlipped ? "flipped" : ""}`}>
          <div className="card-front">
            <div className="form-row">
              <div className="form-column">
                <img
                  src={chip}
                  alt="Card Flag"
                  width={50}
                  className="card-logo"
                />
              </div>
              <div className="form-column position-img">
                <img
                  src={cardLogoMap[cardInfo.flag]}
                  alt="Card Flag"
                  width={80}
                  className="card-logo"
                />
              </div>
            </div>
            <div>
              <div className="text-number">Número</div>
              <div className="card-number">
                {getValues("cartaoNumero") || "XXXX XXXX XXXX XXXX"}
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <div className="text-number">Titular</div>
                <div className="card-name">
                  {getValues("cartaoNomeTitular") || "NOME DO TITULAR"}
                </div>
              </div>
              <div className="form-column">
                <div className="text-number">Validade</div>
                <div className="card-expiry">
                  {getValues("cartaoValidadeMesAno") || "MM/AA"}
                </div>
              </div>
            </div>
          </div>
          <div className="card-back">
            <div className="barra-black"></div>
            <div className="card-cvv">
              {getValues("cartaoCodigoSeguranca") || "XXX"}
            </div>
            <div className="logo-cvv">
              <img
                src={defaultLogo}
                alt="Card Flag"
                width={70}
                className="card-logo"
              />
            </div>
          </div>
        </div>

        <form className="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("cartaoNomeTitular", {
              required: {
                value: true,
                message: "Campo obrigatório",
              },
              maxLength: {
                value: 30,
                message: "Numero máximo de caracteres excedido",
              },
              validate: {
                value: (value) => {
                  const nomeTitularErrorMessage = isPessoaFisica
                    ? "Nome inválido"
                    : "Razão Social inválida";

                  return nomeRazaoRegex.test(value) || nomeTitularErrorMessage;
                },
              },
            })}
            autoFocus
            onBlur={() => trigger("cartaoNomeTitular")}
            className={`payment-form__input ${
              errors.cartaoNomeTitular ? "payment-form__input--error" : ""
            }`}
            type="text"
            name="cartaoNomeTitular"
            placeholder="Nome Impresso no Cartão"
          />
          <p className="payment-form__message">
            {errors && errors.cartaoNomeTitular
              ? errors.cartaoNomeTitular.message
              : ""}
          </p>

          <Controller
            control={control}
            name="cartaoNumero"
            rules={{
              required: { value: true, message: "Campo obrigatório" },
              validate: {
                value: (value) => {
                  return valid.number(value).isValid || "Cartão inválido";
                },
              },
            }}
            render={({ field: { onChange, value } }) => (
              <PatternFormat
                format="#### #### #### ####"
                mask="_"
                onChange={onChange}
                onBlur={() => trigger("cartaoNumero")}
                value={value}
                placeholder="Número do Cartão"
                className={`payment-form__input ${
                  errors.cartaoNumero ? "payment-form__input--error" : ""
                }`}
              />
            )}
          />
          <p className="payment-form__message">
            {errors && errors.cartaoNumero ? errors.cartaoNumero.message : ""}
          </p>

          <div className="form-row">
            <div className="form-row__input-wrapper">
              <Controller
                control={control}
                name="cartaoValidadeMesAno"
                rules={{
                  required: { value: true, message: "Campo obrigatório" },
                  minLength: { value: 7, message: "Data inválida" },
                  validate: {
                    value: (value) => {
                      const validDate = valid.expirationDate(value);
                      return (
                        validDate.isValid ||
                        validDate.isPotentiallyValid ||
                        "Data inválida"
                      );
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PatternFormat
                    className={`payment-form__input ${
                      errors.cartaoValidadeMesAno
                        ? "payment-form__input--error"
                        : ""
                    }`}
                    type="text"
                    placeholder="Validade (MM/AAAA)"
                    onChange={(e) => {
                      if (e.target.value === "__/____") {
                        return onChange("");
                      }
                      return onChange(e.target.value);
                    }}
                    onBlur={() => trigger("cartaoValidadeMesAno")}
                    value={value}
                    format="##/####"
                    mask="_"
                  />
                )}
              />
              <p className="payment-form__message">
                {errors && errors.cartaoValidadeMesAno
                  ? errors.cartaoValidadeMesAno.message
                  : ""}
              </p>
            </div>

            <div className="form-row__input-wrapper">
              <input
                {...register("cartaoCodigoSeguranca", {
                  required: {
                    value: true,
                    message: "Campo obrigatório",
                  },
                })}
                className={`payment-form__input ${
                  errors.cartaoCodigoSeguranca
                    ? "payment-form__input--error"
                    : ""
                }`}
                type="text"
                name="cartaoCodigoSeguranca"
                placeholder="CVV"
                onFocus={handleFocusCvv}
                onBlur={() => {
                  handleBlurCvv();
                  trigger("cartaoCodigoSeguranca");
                }}
                maxLength={3}
              />
              <p className="payment-form__message">
                {errors && errors.cartaoCodigoSeguranca
                  ? errors.cartaoCodigoSeguranca.message
                  : ""}
              </p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-row__input-wrapper">
              <Controller
                control={control}
                name="cartaoCpfTitular"
                rules={{
                  required: { value: true, message: "Campo obrigatório" },
                  validate: {
                    value: (value) => {
                      if (value.length <= 11) {
                        return (value && cpf.isValid(value)) || "CPF inválido";
                      } else {
                        return (
                          (value && cnpj.isValid(value)) || "CNPJ inválido"
                        );
                      }
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PatternFormat
                    className={`payment-form__input ${
                      errors.cartaoCpfTitular
                        ? "payment-form__input--error"
                        : ""
                    }`}
                    type="text"
                    placeholder="CPF/CNPJ"
                    format={
                      value.length <= 11
                        ? "###.###.###-###"
                        : "##.###.###/####-##"
                    }
                    mask=" "
                    onChange={(e) =>
                      onChange(e.target.value.replace(removeNonDigits, ""))
                    }
                    onBlur={() => trigger("cartaoCpfTitular")}
                    value={value}
                  />
                )}
              />

              <p className="payment-form__message">
                {errors && errors.cartaoCpfTitular
                  ? errors.cartaoCpfTitular.message
                  : ""}
              </p>
            </div>

            <div className="form-row__input-wrapper">
              <Controller
                name={"cartaoParcelas"}
                control={control}
                rules={{
                  required: { value: true, message: "Campo obrigatório" },
                  validate: {
                    value: (value) =>
                      (!!value && value !== 0) || "Selecione parcelas",
                  },
                }}
                render={({ field: { onChange } }) => (
                  <select
                    className="payment-form__input"
                    onChange={onChange}
                    onBlur={() => trigger("cartaoParcelas")}
                  >
                    <option>Parcelas</option>
                    <option value={1}>1x</option>
                    <option value={2}>2x</option>
                    <option value={3}>3x</option>
                  </select>
                )}
              />
              <p className="payment-form__message">
                {errors && errors.cartaoParcelas
                  ? errors.cartaoParcelas.message
                  : ""}
              </p>
            </div>
          </div>
          <div className="space-buttons">
            <button
              className={`${formState.isValid ? "" : "form-submit--disabled"} ${
                isLoading ? "form-submit--unclickable" : ""
              }`}
              type="submit"
            >
              {isLoading ? <LoadingDots size="small" /> : "FINALIZAR PAGAMENTO"}
            </button>
            <button
              className={`secondary ${
                isLoading ? "form-submit--disabled" : ""
              }`}
              onClick={() => {
                onClose();
                setCurrentStep(37);
              }}
            >
              VOLTAR
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentModal;
