import steps from "./steps";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../../utils/setLoadingMessage";

// Current step is index or name
const findStep = (currentStep) => {
  const objectSteps = steps.reduce((acc, step, index) => {
    step.index = index;
    acc[step.name || index] = step;
    return acc;
  }, {});

  return objectSteps[currentStep];
};

export default findStep;
