import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./screens/errorPage";
import { TipoBotEnum } from "./utils/constants";

const BOT_HOME = {
  DEFAULT: {
    botType: TipoBotEnum.MYCON,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
  LIVE: {
    botType: TipoBotEnum.LIVE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
  ANIVERSARIO: {
    botType: TipoBotEnum.ANIVERSARIO,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App {...BOT_HOME.LIVE} />,
  },
  {
    path: "/ativo",
    element: (
      <App
        botType={TipoBotEnum.MODO_TURBO}
        title="Mycon, o consórcio com a menor taxa do Brasil - Promoção Modo Conquista"
        description="Oferta para você ir com tudo na sua conquista com o Mycon."
        indexar="noindex,nofollow"
      />
    ),
  },
  {
    path: "/performance",
    element: (
      <App
        botType={TipoBotEnum.PERFORMANCE}
        title="Mycon, o consórcio com a menor taxa do Brasil - Promoção Performance"
        description="50% de redução no valor das parcelas até contemplar. Aproveite! É especial para você, que já é de casa e conhece o Mycon."
        indexar="noindex,nofollow"
      />
    ),
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export default router;
