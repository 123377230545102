import React, { useState, useEffect, useRef } from "react";
import "./bot.scss";
import icon from "../../assets/icons/icon.png";
import ServicesCarousel from "../../components/servicesCarousel";
import PlanListCarousel from "../../components/plan-list-carousel/plan-list-carousel";
import Options from "../../components/options-buttons/options-buttons";
import PaymentModal from "../../components/card-payment/card-payment";
import steps from "./stepModules/steps";
import BotInputs from "../../components/bot-inputs";
import useBotControlStore from "../../storeModules/useBotControlStore";
import useOnceEffect from "../../utils/hooks/useOnceEffect";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import useInitialConfigStore from "../../storeModules/useInitialConfigStore";
import TypeOptions from "../../components/type-options/type-options";
import findStep from "./stepModules/findStep";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../utils/setLoadingMessage";
import sleepAwait from "../../utils/sleepAwait";

const ChatBot = ({ bannerSrc }) => {
  const messagesEndRef = useRef(null);
  const [hasToShowTitle, setHasToShowTitle] = useState(false);
  const {
    messages,
    setMessages,
    currentStep,
    setCurrentStep,
    isBotLoading,
    showPaymentModal,
    setShowPaymentModal,
  } = useBotControlStore((state) => state);

  const [plansMap, setPlansMap] = useState([]);
  const [servicesMap, setServicesMap] = useState([]);
  const { fetchedPlans, setConsorcioStore } = useConsorcioStore(
    (state) => state
  );
  const { tipoTabela, fakeLoad } = useInitialConfigStore((state) => state);

  useOnceEffect(() => {
    setCurrentStep(0);
    // const selectedStep = findStep("error-no-plans");
    // console.log("selectedStep --->", selectedStep);
  });

  useEffect(() => {
    const mappedPlans = fetchedPlans
      .filter((plan) => plan.planoID)
      .map((plan) => {
        return {
          text: `<b>${plan.bemCredito.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}</b><br>em ${
            plan.planoMeses
          } parcelas <br>de <b>${plan.planoValorParcela.toLocaleString(
            "pt-BR",
            { style: "currency", currency: "BRL" }
          )}</b>`,
          description: plan.labelPlano,
          icon: plan.imagemUrlPlano,
          bem: plan.bemNome,
          setSelectedPlan: () => {
            setConsorcioStore(plan, "selectedPlano");
          },
        };
      });

    setPlansMap(mappedPlans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedPlans]);

  useEffect(() => {
    const mappedServices = TypeOptions().filter((service) => {
      if (tipoTabela === "paga_metade") {
        return service.text !== "Serviços";
      }
      return service;
    });

    setServicesMap(mappedServices);
  }, [tipoTabela]);

  const shouldShowLogo = (index) => {
    return (
      index === messages.length - 1 ||
      messages[index + 1]?.sender !== messages[index]?.sender
    );
  };

  useEffect(() => {
    async function handleStep() {
      if (currentStep >= 0 && steps[currentStep]) {
        const shouldFakeLoad = !steps[currentStep].isWaitingForFetch;

        if (shouldFakeLoad) {
          setLoadingMessage();
          await sleepAwait(fakeLoad);
          removeLoadingMessage();
        }

        const step = steps[currentStep];
        const time = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        const newMessage = {
          sender: "bot",
          text: typeof step.text === "function" ? step.text() : step.text,
          time: time,
          component: step.component || null,
        };

        if (steps[currentStep].text || steps[currentStep].component) {
          setMessages(newMessage);
        }

        if (step.action && !step.inputType) {
          step.action();
        }

        if (!step.inputType && !step.options && step.nextStep) {
          setCurrentStep(step.nextStep);
        }
      }
    }
    handleStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const handleOptionClick = (option) => {
    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const userMessage = {
      sender: "user",
      text: option.text,
      time: time,
    };

    setMessages(userMessage);
    if (option.nextStep !== undefined) {
      setTimeout(() => {
        setCurrentStep(option.nextStep);
      }, 0);
    }

    if (option.setSelectedPlan) {
      option.setSelectedPlan();
    }
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  useEffect(() => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  }, [messages, currentStep]);

  useEffect(() => {
    // setBotIsLoading(true);
    // setShowPaymentModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="full">
      <div className="container">
        <div className="banner">
          <img src={bannerSrc} alt="Icon" />
        </div>

        <div className="messages">
          <>
            {messages.map((msg, index) => {
              return (
                <div
                  onClick={
                    msg.title
                      ? () => {
                          setHasToShowTitle(true);
                          setTimeout(() => {
                            setHasToShowTitle(false);
                          }, 3000);
                        }
                      : null
                  }
                  key={index}
                  className={`message-row ${msg.sender}`}
                >
                  {msg.title && hasToShowTitle && (
                    <span className={`message-tooltip ${msg.sender}`}>
                      {msg.title}
                    </span>
                  )}

                  <div
                    className={`bot-logo ${msg.sender} ${
                      msg.sender === "bot" && shouldShowLogo(index)
                        ? "last-logo"
                        : "space"
                    }`}
                  >
                    {msg.sender === "bot" && shouldShowLogo(index) && (
                      <img src={icon} className="icon" alt="Logo" />
                    )}
                  </div>

                  <div className={`message-bubble ${msg.sender}`}>
                    {msg.component && (
                      <div className="message-bubble--component">
                        {msg.component}
                      </div>
                    )}

                    {msg.text && (
                      <p
                        className="message-ellipsis"
                        dangerouslySetInnerHTML={{ __html: msg.text }}
                        title={msg.title ? msg.title : ""}
                      ></p>
                    )}

                    {/* <span className="message-time">{msg.time}</span> */}
                  </div>
                </div>
              );
            })}
            <div className="input-area">
              {steps[currentStep]?.inputType && !isBotLoading && (
                <BotInputs
                  inputType={steps[currentStep].inputType}
                  submitAction={steps[currentStep].action}
                />
              )}
            </div>
          </>
          <div ref={messagesEndRef} id="messages-ref" />
        </div>

        {!isBotLoading && (
          <>
            <PaymentModal
              isVisible={showPaymentModal}
              onClose={closePaymentModal}
            />

            {steps[currentStep]?.useCarousel && (
              <ServicesCarousel
                options={servicesMap}
                onOptionClick={handleOptionClick}
              />
            )}

            {steps[currentStep]?.useCarouselPlan && (
              <PlanListCarousel
                options={plansMap}
                onOptionClick={handleOptionClick}
              />
            )}

            <div
              className={`options-container ${
                currentStep === 30 ||
                currentStep === 48 ||
                currentStep === 49 ||
                currentStep === findStep("pesquisa-humanos-pontuacao").index
                  ? "customButtons"
                  : ""
              }`}
            >
              {!steps[currentStep]?.useCarouselPlan &&
                !steps[currentStep]?.useCarousel &&
                steps[currentStep]?.options && (
                  <Options
                    options={steps[currentStep].options}
                    currentStep={currentStep}
                    handleOptionClick={handleOptionClick}
                  />
                )}
            </div>

            <div className="privacy-policy">
              *Ao continuar você estará de acordo com nossa{" "}
              <a
                href="https://www.mycon.com.br/politica-de-privacidade"
                target="_blank"
                rel="noopener noreferrer"
              >
                <u>Política de Privacidade</u>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBot;
